export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Form and parse dates */
  DateTime: any;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
  /** Uploaded file */
  UploadedFile: any;
  /** The `Void` scalar type represents `null`. */
  Void: any;
};

/** Explanation of the correct answer */
export type AnswerExplanation = {
  __typename?: 'AnswerExplanation';
  /** Media */
  media: Array<File>;
  /** Text explanation */
  text?: Maybe<Scalars['String']>;
};

/** Explanation of the correct answer */
export type AnswerExplanationInput = {
  /** Media explanation */
  media?: InputMaybe<Array<Scalars['UploadedFile']>>;
  /** Text explanation */
  text?: InputMaybe<Scalars['String']>;
};

/** Type of question answer */
export enum AnswerType {
  /** Option */
  Option = 'OPTION',
  /** Text */
  Text = 'TEXT'
}

/** Help article */
export type Article = {
  __typename?: 'Article';
  /** Article content */
  content: Scalars['String'];
  /** The id of the article */
  id: Scalars['ID'];
  /** Article caption */
  title: Scalars['String'];
};

/** Game avatar set */
export type AvatarSet = {
  __typename?: 'AvatarSet';
  /** The id of the set */
  id: Scalars['ID'];
  /** Preview image */
  previewUrl?: Maybe<Scalars['String']>;
  /** Set caption */
  title: Scalars['String'];
};

/** Currency */
export enum Currency {
  /** Russian ruble */
  Rub = 'RUB'
}

/** File info */
export type File = {
  __typename?: 'File';
  file: Scalars['UploadedFile'];
  thumbnail?: Maybe<Scalars['String']>;
  type: FileType;
  url: Scalars['String'];
};

/** Type of the file */
export enum FileType {
  /** Audio */
  Audio = 'AUDIO',
  /** Image */
  Image = 'IMAGE',
  /** Video */
  Video = 'VIDEO'
}

/** Quiz game */
export type Game = {
  __typename?: 'Game';
  /** Selected avatar set */
  avatarSet: AvatarSet;
  /** The id of the game */
  id: Scalars['ID'];
  /**
   * Game cover image
   * @deprecated Use imageFile instead
   */
  image?: Maybe<Scalars['String']>;
  /** Game cover image */
  imageFile?: Maybe<File>;
  /** Is game ready to launch */
  isReady?: Maybe<Scalars['Boolean']>;
  /** Show round results without animation */
  isResultsQuick: Scalars['Boolean'];
  /** Voiceovers automatic generation */
  isVoiceoverEnabled: Scalars['Boolean'];
  /** Created rounds */
  rounds: Array<Round>;
  /** Selected sound set */
  soundSet: SoundSet;
  /** Selected theme */
  theme: Theme;
  /** Game caption */
  title: Scalars['String'];
  /** Access tokens */
  tokens: Array<GameToken>;
};

/** Game data */
export type GameInput = {
  /** Id of an avatar set for the game */
  avatarSetId: Scalars['ID'];
  /** Cover image */
  image?: InputMaybe<Scalars['UploadedFile']>;
  /** Show round results without animation */
  isResultsQuick?: Scalars['Boolean'];
  /** Voiceovers automatic generation */
  isVoiceoverEnabled?: Scalars['Boolean'];
  /** Id of a sound set for the game */
  soundSetId: Scalars['ID'];
  /** Id of a theme for the game */
  themeId: Scalars['ID'];
  /** A game title (250 max chars) */
  title: Scalars['String'];
};

/** Token to get access to the game */
export type GameToken = {
  __typename?: 'GameToken';
  /** The id of the game */
  id: Scalars['ID'];
  /** Game link */
  url: Scalars['String'];
};

/** User payment invoice */
export type Invoice = {
  __typename?: 'Invoice';
  /** Amount */
  amount: Scalars['Float'];
  /** Currency */
  currency: Currency;
  /** Created date */
  date: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** Payment order ID */
  orderId: Scalars['String'];
  /** Package selected */
  package: Package;
  /** Url for payment */
  paymentUrl: Scalars['String'];
  /** Status */
  status: InvoiceStatus;
};

/** Status of the invoice */
export enum InvoiceStatus {
  /** Failed to pay */
  Failed = 'FAILED',
  /** Just created */
  Open = 'OPEN',
  /** Paid successfully */
  Paid = 'PAID',
  /** In processing */
  Processing = 'PROCESSING',
  /** Refund of payment */
  Refunded = 'REFUNDED',
  /** Expired or cancelled */
  Void = 'VOID'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Upload a file to provide in other places */
  fileUpload: File;
  gameCreate: Game;
  gameRemove?: Maybe<Scalars['Void']>;
  /** Create a new active game token */
  gameTokenCreate: Game;
  gameUpdate: Game;
  /** Login by email */
  login?: Maybe<Scalars['Void']>;
  /** Buy a package */
  packageBuy: Invoice;
  /** Change position of the question within a round */
  questionChangePosition: Question;
  /** Change round of the question */
  questionChangeRound: Question;
  /** Change visibility status */
  questionChangeStatus: Question;
  questionCreate: Question;
  questionRemove?: Maybe<Scalars['Void']>;
  questionUpdate: Question;
  /** Change position of the round within a game */
  roundChangePosition: Round;
  /** Change visibility status */
  roundChangeStatus: Round;
  roundCreate: Round;
  roundRemove?: Maybe<Scalars['Void']>;
  roundUpdate: Round;
};


export type MutationFileUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationGameCreateArgs = {
  game: GameInput;
};


export type MutationGameRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationGameTokenCreateArgs = {
  gameId: Scalars['ID'];
};


export type MutationGameUpdateArgs = {
  game: GameInput;
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
};


export type MutationPackageBuyArgs = {
  id: Scalars['ID'];
  returnUrl: Scalars['String'];
};


export type MutationQuestionChangePositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};


export type MutationQuestionChangeRoundArgs = {
  id: Scalars['ID'];
  roundId: Scalars['ID'];
};


export type MutationQuestionChangeStatusArgs = {
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};


export type MutationQuestionCreateArgs = {
  question: QuestionInput;
  roundId: Scalars['ID'];
};


export type MutationQuestionRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationQuestionUpdateArgs = {
  id: Scalars['ID'];
  question: QuestionInput;
};


export type MutationRoundChangePositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};


export type MutationRoundChangeStatusArgs = {
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};


export type MutationRoundCreateArgs = {
  gameId: Scalars['ID'];
  round: RoundInput;
};


export type MutationRoundRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationRoundUpdateArgs = {
  id: Scalars['ID'];
  round: RoundInput;
};

/** Payment package */
export type Package = {
  __typename?: 'Package';
  /** Balance provided */
  balance: Scalars['Int'];
  /** Currency */
  currency: Currency;
  /** Description */
  description: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** Price */
  price: Scalars['Float'];
  /** Caption */
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  article: Article;
  articles: Array<Article>;
  game: Game;
  /** Invoice details */
  invoice: Invoice;
  /** Payment packages list */
  packages: Array<Package>;
  question: Question;
  round: Round;
  settings: Settings;
  user: User;
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
};


export type QueryGameArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionArgs = {
  id: Scalars['ID'];
};


export type QueryRoundArgs = {
  id: Scalars['ID'];
};

/** Question */
export type Question = {
  __typename?: 'Question';
  /** Answer texts for direct answering */
  answer: Array<Scalars['String']>;
  /** Explanation of the correct answer */
  answerExplanation: AnswerExplanation;
  /** Type of answer */
  answerType: AnswerType;
  /** The id of the question */
  id: Scalars['ID'];
  /** Question media */
  media: Array<File>;
  /** Question options for answering by an option */
  options: Array<QuestionOption>;
  /** Question preview link */
  previewUrl: Scalars['String'];
  /** Visibility status */
  published: Scalars['Boolean'];
  /** Question text */
  text: Scalars['String'];
};

/** Question data */
export type QuestionInput = {
  /** Answer texts for direct answering */
  answer: Array<Scalars['String']>;
  /** Explanation of the correct answer */
  answerExplanation?: InputMaybe<AnswerExplanationInput>;
  /** Type of answer */
  answerType: AnswerType;
  /** Question media */
  media?: InputMaybe<Array<Scalars['UploadedFile']>>;
  /** Question options for answering by an option */
  options: Array<QuestionOptionInput>;
  /** Question text */
  text: Scalars['String'];
};

/** Option of the question */
export type QuestionOption = {
  __typename?: 'QuestionOption';
  /** The id of the option */
  id: Scalars['ID'];
  /** Option is correct */
  isCorrect: Scalars['Boolean'];
  /** Option text */
  text: Scalars['String'];
};

/** Question option data */
export type QuestionOptionInput = {
  /** Right or wrong option to answer */
  isCorrect: Scalars['Boolean'];
  /** An option text (250 max chars) */
  text: Scalars['String'];
};

/** Game round */
export type Round = {
  __typename?: 'Round';
  /** The id of the round */
  id: Scalars['ID'];
  /** Whether questions are shuffled */
  isShuffled: Scalars['Boolean'];
  /** Visibility status */
  published: Scalars['Boolean'];
  /** Related questions list */
  questions: Array<Question>;
  /** Scores for a right answer */
  scoreRatio: Scalars['Int'];
  /** Time to answer (seconds) */
  timeToAnswer: Scalars['Int'];
  /** Round caption */
  title: Scalars['String'];
};

/** Round data */
export type RoundInput = {
  /** Whether questions are shuffled */
  isShuffled: Scalars['Boolean'];
  /** Scores for a right answer */
  scoreRatio: Scalars['Int'];
  /** Time to answer (seconds) */
  timeToAnswer: Scalars['Int'];
  /** A round title (250 max chars) */
  title: Scalars['String'];
};

/** App settings */
export type Settings = {
  __typename?: 'Settings';
  /** Launch a game dialog content */
  gameLaunch?: Maybe<Scalars['String']>;
  /** Rules content */
  rules?: Maybe<Scalars['String']>;
  /** Rules short content */
  rulesShort?: Maybe<Scalars['String']>;
  /** Available tooltips list */
  tooltips: Array<Tooltip>;
};

/** Game avatar set */
export type SoundSet = {
  __typename?: 'SoundSet';
  /** The id of the set */
  id: Scalars['ID'];
  /** Preview image */
  previewUrl?: Maybe<Scalars['String']>;
  /** Set caption */
  title: Scalars['String'];
};

/** Game theme */
export type Theme = {
  __typename?: 'Theme';
  /** The id of the theme */
  id: Scalars['ID'];
  /** Preview image */
  previewUrl?: Maybe<Scalars['String']>;
  /** Theme caption */
  title: Scalars['String'];
};

/** UI tooltip info */
export type Tooltip = {
  __typename?: 'Tooltip';
  /** System name */
  code: Scalars['String'];
  /** Text */
  value: Scalars['String'];
};

/** Current user */
export type User = {
  __typename?: 'User';
  /** Avatar sets available */
  avatarSets: Array<AvatarSet>;
  /** Game launch balance */
  balance: Scalars['Int'];
  /** Balance history */
  balanceRevisions: Array<UserBalanceRevision>;
  /** The email of the user */
  email: Scalars['String'];
  /** Created games */
  games: Array<Game>;
  /** ID */
  id: Scalars['ID'];
  /** Payment invoices */
  invoices: Array<Invoice>;
  /** The name of the user */
  name: Scalars['String'];
  /** Sound sets available */
  soundSets: Array<SoundSet>;
  /** Themes available */
  themes: Array<Theme>;
};

/** Balance history record interface */
export type UserBalanceRevision = {
  /** Final balance */
  balance: Scalars['Int'];
  /** Event date */
  date: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
};

/** Game played balance history record */
export type UserBalanceRevisionGamePlayed = UserBalanceRevision & {
  __typename?: 'UserBalanceRevisionGamePlayed';
  /** Final balance */
  balance: Scalars['Int'];
  /** Event date */
  date: Scalars['DateTime'];
  /** Related game */
  game: Game;
  /** ID */
  id: Scalars['ID'];
};

/** Top up balance history record */
export type UserBalanceRevisionTopUp = UserBalanceRevision & {
  __typename?: 'UserBalanceRevisionTopUp';
  /** Final balance */
  balance: Scalars['Int'];
  /** Created date */
  date: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** Related invoice */
  invoice: Invoice;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "UserBalanceRevision": [
      "UserBalanceRevisionGamePlayed",
      "UserBalanceRevisionTopUp"
    ]
  }
};
      export default result;
    